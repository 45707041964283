import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import Envelope from "../assets/images/svg/envelope.svg";
import Phone from "../assets/images/svg/phone.svg";

import { container, mail, links } from "./contact.module.scss";

import MainLayout from "../layouts/main-layout";
import Title from "../components/atoms/title";
import { getNodes } from "../utils/get-nodes";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { IQueryAllResult } from "../models/query-all-result.model";

interface IContactProps {
  className: string;
  readonly data: {
    image: IQueryAllResult<IGatsbyImageData>;
  };
}

const ContactPage: React.FC<IContactProps> = ({ data }) => {
  const { t } = useTranslation();
  const { image } = data;
  const imageData = getNodes(image)[0];
  const imageContact = getImage(imageData);

  return (
    <MainLayout
      isContact={true}
      titleSeo={t("contact.us.title")}
      descriptionSeo={t("contact.us.title")}
    >
      <Title Tag="h1" size="large" align="center">
        {t("contact.us.title")}
      </Title>
      <div className="">
        <div className={links}>
          <a href="mailto:office@sdp.legal" className={mail}>
            <Envelope />
            <span> office@sdp.legal</span>
          </a>
          <a href="tel:605304630" className={mail}>
            <Phone />
            <span> 605-304-630</span>
          </a>
        </div>
        {imageContact && (
          <div className={container}>
            <GatsbyImage alt={""} image={imageContact} />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    image: allFile(filter: { relativePath: { eq: "bg-contact.jpg" } }) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default ContactPage;
